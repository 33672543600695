$(document).ready(function() {
    $('.nav-trigger').on('click', function() {

        var menu = $('.nav-menu > ul.main-list'),
            parent = $('.site-header');

        menu
            .toggleClass('open')
            .slideDown(250);

        parent
            .addClass('open-menu');

        $(this).hide();
    });
});
